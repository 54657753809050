import React, { useEffect, useState } from 'react';
import { IoAdd } from 'react-icons/io5';
import { formatDateForInput } from '../utils/formatTimestamp';
import { IoRadioButtonOnSharp } from "react-icons/io5";
import { SlCalender } from "react-icons/sl";
import { LuTimer, LuUsers2 } from "react-icons/lu";
import { DeleteRequest, PutRequest, GetRequest } from '../utils/request';
import ReactModal from '.';
import Timer from '../components/task/timer';
import Avatar from '../components/custom/avatar';
import { useDispatch, useSelector } from 'react-redux';
import { AiTwotoneDelete } from "react-icons/ai";
import IconButton from '../components/custom/iconButton';
import RichText from '../components/custom/richText';
import { taskActions } from '../store/task-slice';
import TaskSocket from "../socket/task-socket";
import Activities from '../components/task/activities';
import AddAssignees from './addAssignees';
import getInitialLetter from '../utils/getInitialLetter';
import { IoIosClose } from 'react-icons/io';

const TaskView = ({ setTrigger = () => { }, projectId }) => {
    const dispatch = useDispatch()
    const { accountInfo } = useSelector((state) => state.account);
    const isAdmin = accountInfo?.data.role?.title?.toLowerCase() === "admin";

    const { 
        modalOpen, 
        milestones, 
        selectedTaskData, 
        selectedMilestone, 
        taskAssignees, 
        projectTrigger, 
        assigneesModalOpen 
    } = useSelector((state) => state.task);

    const [assignees, setAssignees] = useState(taskAssignees);
    const [description, setDescription] = useState(false)

    const [show, setShow] = useState({
        milestone: false,
        assignees: false
    });
    const [milestone, setMilestone] = useState(selectedMilestone);
    const [formInput, setFormInput] = useState({
        title: selectedTaskData?.title || "",
        description: selectedTaskData?.description || "",
        dueDate: selectedTaskData?.dueDate || null,
    })

    useEffect(() => {
        setFormInput({
            title: selectedTaskData?.title || "",
            description: selectedTaskData?.description || "",
            dueDate: selectedTaskData?.dueDate || null
        })
    }, [selectedTaskData]);

    useEffect(() => {
        const allAssignees = [...selectedTaskData.assignees, ...selectedTaskData.departments];
        setAssignees(allAssignees)
    }, [selectedTaskData]);

    useEffect(() => {
        setMilestone(selectedMilestone)
        setActivityTrigger(prev => prev + 1);
    }, [selectedMilestone]);

    const [trigger, setActivityTrigger] = useState(0);

    // GET DESCRIPTION OF THE TASK
    useEffect(() => {
        GetRequest(`${process.env.REACT_APP_URL}/task/description/${selectedTaskData?._id}`).then((response) => {
            setDescription(response?.data.description)
            setFormInput(prevState => ({ ...prevState, description: response?.data.description }))
        }).catch((error) => {
            console.error("Error fetching task details:", error);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // UPDATES MILESTONE/STATUS OF THE TASK
    function updateMilestoneHandler(milestoneId) {
        PutRequest(`${process.env.REACT_APP_URL}/task/${selectedTaskData._id}`, {
            milestoneId: milestoneId,
            updatedBy: accountInfo?.data.fullName
        }).then(response => {
            TaskSocket.emitTaskUpdate(projectId, response.data, "", "milestone updated")
            setActivityTrigger(prev => prev + 1);
        }).catch(error => {
            console.log("error updating milestone", error.data)
        })
    }

    // UPDATES ASSIGNEES OF THE TASK
    function updateAssigneeHandler(body) {
        body.updatedBy= accountInfo?.data.fullName;
        PutRequest(`${process.env.REACT_APP_URL}/task/assignee/${selectedTaskData._id}`, body).then(response => {
            TaskSocket.emitTaskUpdate(projectId, response.data, assignees, "assignee updated", body.assignee)
            setTrigger(prev => prev + 1);
        }).catch(error => {
            console.log("error updating milestone", error)
        })
    }

    // UPDATES ASSIGNEES OF THE TASK
    function updateDueDateHandler() {
        // if (formInput.dueDate !== selectedTaskData?.dueDate) {
        let body = {
            dueDate: new Date(formInput.dueDate),
            updatedBy: accountInfo?.data.fullName
        };
        PutRequest(`${process.env.REACT_APP_URL}/task/${selectedTaskData._id}`, body).then(response => {
            // setTrigger(prev => prev + 1);
            TaskSocket.emitTaskUpdate(projectId, response.data, "", "task updated")
        }).catch(error => {
            console.log("error updating milestone", error)
        })
        // }
    }

    const formattedDate = formatDateForInput(formInput?.dueDate);

    // UPDATES DESCRIPTION OF THE TASK
    function updateHandler() {
        let body = {
            title: formInput?.title,
            description: formInput?.description
        };
        PutRequest(`${process.env.REACT_APP_URL}/task/${selectedTaskData._id}`, body).then(response => {
            TaskSocket.emitTaskUpdate(projectId, response.data, "", "task updated")
        }).catch(error => {
            console.log("error updating due date", error)
        })
    }

    function closeHandler() {
        if (formInput.title?.trim().length === 0 && selectedTaskData?.title.length > 0 && isAdmin) {
            setFormInput(prevState => ({ ...prevState, title: selectedTaskData?.title }))
        } else if (
            (formInput.title !== selectedTaskData?.title ||
                formInput.description !== description) && isAdmin) {
            updateHandler();
        } else if ((formInput.dueDate !== selectedTaskData?.dueDate) && isAdmin) {
            updateDueDateHandler()
        }
        dispatch(taskActions.setModalOpen(false));
        dispatch(taskActions.setSelectedMilestone(""));
        dispatch(taskActions.setSelectedTaskData(""));
        dispatch(taskActions.setTaskAssignees([]));
        dispatch(taskActions.setTaskDepartments([]))
        dispatch(taskActions.setProjectTrigger(projectTrigger + 1))
    }

    function deleteTaskHandler() {
        DeleteRequest(`${process.env.REACT_APP_URL}/task/${selectedTaskData._id}`).then(response => {
            dispatch(taskActions.setModalOpen(false));
            dispatch(taskActions.setSelectedMilestone(""));
            dispatch(taskActions.setSelectedTaskData(""));
            dispatch(taskActions.setTaskAssignees([]));
            dispatch(taskActions.setTaskDepartments([]))

            setTrigger(prev => prev + 1);
            TaskSocket.emitTaskUpdate(projectId, selectedTaskData._id, taskAssignees, "task deleted")
        }).catch(error => {
            console.log("delete task error >>>", error.data);
        });
    }

    return (
        <ReactModal open={modalOpen} close={closeHandler} maxWidth='1600px' padding='20px' >
            {assigneesModalOpen &&
                <AddAssignees />
            }
            <div className='flex max-lg:flex-col gap-[10px] h-[85vh] p-[10px]'>
                <div className='lg:w-[70%] md:pr-[40px]'>

                    {/* TITLE OF THE TASK */}
                    <div className='border-b pb-[10px]'>
                        <input
                            maxLength={50}
                            minLength={5}
                            disabled={!isAdmin}
                            value={formInput.title}
                            onChange={(e) => {
                                setFormInput(prevState => ({ ...prevState, title: e.target.value }))
                            }}
                            className='capitalize border-none select-none focus:outline-none font-semibold h-[35px] px-[10px] w-full'
                        />
                    </div>
                    <div className='grid md:grid-cols-2 md:mt-[30px] max-md:mt-[20px] gap-x-[70px] gap-y-[20px]'>

                        {/* STATUS OF THE TASK */}
                        <div className='relative flex w-full md:max-w-[300px] justify-between relative items-center'>
                            <span className='text-[14px] flex items-center w-full'><IoRadioButtonOnSharp size={15} className='mr-[5px]' /> Status</span>
                            <div className='relative w-full'>
                                <div
                                    className="px-[10px] text-[12px] py-[5px] cursor-pointer select-none w-full text-white uppercase rounded-[5px] flex w-full"
                                    style={{ backgroundColor: milestone?.color }}
                                    onClick={() => setShow(prevState => ({ ...prevState, milestone: !show.milestone }))}
                                >{milestone?.title}
                                </div>
                                <div className={`absolute top-[31px] left-0 min-w-[190px] w-full bg-hover p-[10px] rounded-[10px] shadow-md z-[10] ${show.milestone ? "grid" : "hidden"}`} onMouseLeave={() => setShow(prevState => ({ ...prevState, milestone: false }))}>
                                    {milestones?.length > 0 && milestones.map((item, i) => (
                                        <button
                                            key={i}
                                            className="flex gap-[10px] cursor-pointer py-[3px] items-center border-b last:border-b-0"
                                            onClick={() => {
                                                setMilestone(item);
                                                updateMilestoneHandler(item?._id)
                                                setShow(prevState => ({ ...prevState, milestone: false }));
                                            }}
                                        >
                                            <div className='h-[13px] w-[13px] rounded-full border flex justify-center items-center' style={{ borderColor: item.color }}>
                                                <div className='h-[9px] w-[9px] rounded-full' style={{ backgroundColor: item.color }} />
                                            </div>
                                            <span className="text-[12px] uppercase select-none text-left">{item?.title}</span>
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </div>

                        {/* ASSIGNEES OF THE TASK */}
                        <div className='flex w-full md:max-w-[300px] justify-between gap-[15px]'>
                            <span className='text-[14px] flex items-center w-full'><LuUsers2 size={15} className='mr-[5px]' /> Assignees</span>
                            <div className="relative flex items-center w-full" >
                                {assignees.length > 0 && (
                                    <div className="relative flex items-center w-full" >
                                        {assignees.slice(0, 3).map((item, i) => (
                                            <>
                                            {item.title ? (
                                                <>
                                                <div title={item.title} className='h-[40px] w-[40px] rounded-full border border-[#aaa] flex justify-center items-center ml-[-5px] first:ml-0 bg-secondary'>
                                                    {getInitialLetter(item.title)}
                                                </div>
                                                {isAdmin &&
                                                    <IoIosClose
                                                        size={20}
                                                        className="absolute top-0 right-0 z-[999] bg-white rounded-full cursor-pointer group-hover:block hidden"
                                                        onClick={() => {
                                                            updateAssigneeHandler({department: item?._id})
                                                            const filtered = assignees.filter(assignee => assignee._id !== item._id)
                                                            setAssignees(filtered)
                                                        }}
                                                    />
                                                }
                                                </>
                                            ):(
                                                <>
                                                <Avatar
                                                    src={item.avatar}
                                                    alt={item.fullName}
                                                    title={item.fullName}
                                                    key={i}
                                                    noOnline
                                                    className={`ml-[-5px] first:ml-0`}
                                                />
                                                {isAdmin &&
                                                    <IoIosClose
                                                        size={20}
                                                        className="absolute top-0 right-0 z-[999] bg-white rounded-full cursor-pointer group-hover:block hidden"
                                                        onClick={() => {
                                                            updateAssigneeHandler({assignee: item?._id})
                                                            const filtered = assignees.filter(assignee => assignee._id !== item._id)
                                                            setAssignees(filtered)
                                                        }}
                                                    />
                                                }
                                                </>
                                            )}
                                            </>
                                        ))}
                                        {assignees.length > 3 && (
                                            <div className='h-[40px] w-[40px] flex justify-center items-center border border-[#aaa] rounded-full bg-white ml-[-10px] relative'>+{assignees.length - 3}</div>
                                        )}
                                    </div>
                                )}
                                {isAdmin &&
                                    <button onClick={() => dispatch(taskActions.setAssigneesModalOpen(true))}>
                                        <IoAdd size={20} />
                                    </button>
                                }
                                {/* <div className={`absolute top-[40px] left-0 min-w-[200px] w-full bg-white py-[10px] px-[5px] rounded-[10px] shadow-lg z-[10] overflow-hidden overflow-y-auto max-h-[180px] ${show.assignees ? "grid" : "hidden"}`} >
                                    <button className="absolute top-[5px] right-[5px]" onClick={() => setShow(prevState => ({ ...prevState, assignees: false }))}>
                                        <IoClose size={20} />
                                    </button>
                                    {isAdmin ? (
                                        employees?.length > 0 && employees.map((item, i) => (
                                            <button
                                                key={i}
                                                className="flex gap-[10px] cursor-pointer py-[5px] px-[10px] items-center bg-hover"
                                                onClick={() => {
                                                    updateAssigneeHandler(item?._id)
                                                    if (!assignees.some(assignee => assignee._id === item._id)) {
                                                        const updatedItems = [...assignees, item];
                                                        setAssignees(updatedItems);
                                                    } else {
                                                        const filtered = assignees.filter(index => {
                                                            return index._id !== item?._id;
                                                        })
                                                        setAssignees(filtered);
                                                    }
                                                }}
                                            >
                                                <Avatar
                                                    src={item?.avatar}
                                                    alt={item?.fullName}
                                                    noOnline
                                                    className={`${assignees.some(assignee => assignee._id === item._id) && "!border-[2px] border-black"}`}
                                                />
                                                <span className="text-[14px] capitalize select-none">{item?.fullName}</span>
                                            </button>
                                        ))
                                    ) : (
                                        chatList?.length > 0 && chatList.map((item, i) => (
                                            <button
                                                key={i}
                                                className="flex gap-[10px] cursor-pointer py-[5px] px-[10px] items-center bg-hover"
                                                onClick={() => {
                                                    updateAssigneeHandler(item.participants[0]._id)
                                                    if (!assignees.some(assignee => assignee._id === item.participants[0]._id)) {
                                                        const updatedItems = [...assignees, item.participants[0]];
                                                        setAssignees(updatedItems);
                                                    } else {
                                                        const filtered = assignees.filter(index => {
                                                            return index._id !== item.participants[0]._id;
                                                        })
                                                        setAssignees(filtered);
                                                    }
                                                }}
                                            >
                                                <Avatar
                                                    src={item?.participants[0]?.avatar}
                                                    alt={item?.participants[0]?.fullName}
                                                    noOnline
                                                    className={`${assignees.some(assignee => assignee._id === item.participants[0]._id) && "!border-[2px] border-black"}`}
                                                />
                                                <span className="text-[14px] capitalize select-none">{item?.participants[0]?.fullName}</span>
                                            </button>
                                        ))
                                    )}
                                </div> */}
                                {/* } */}
                            </div>
                        </div>

                        {/* DUE DATE OF THE TASK */}
                        <div className='flex w-full md:max-w-[300px] justify-between'>
                            <span className='text-[14px] flex items-center w-full'><SlCalender size={13} className='mr-[5px]' /> Due Date</span>
                            <input
                                type='datetime-local'
                                value={formattedDate}
                                disabled={!isAdmin}
                                className='text-[14px] w-full'
                                onChange={(e) => {
                                    setFormInput(prevState => ({ ...prevState, dueDate: e.target.value }))
                                }}
                            />
                        </div>

                        {/* TASK TIME TRACKER */}
                        <div className='flex w-full md:max-w-[300px] justify-between'>
                            <span className='text-[14px] flex items-center'><LuTimer size={15} className='mr-[5px]' /> Track Time</span>
                            <span className='text-[14px]'>
                                <Timer />
                            </span>
                        </div>
                    </div>

                    {/* DESCRIPTION OF THE TASK */}
                    <div className='w-full mt-[20px]'>
                        <RichText
                            readOnly={!isAdmin}
                            value={formInput.description}
                            placeholder="Write description here..."
                            onChange={(input) => {
                                setFormInput(prevState => ({ ...prevState, description: input }));
                            }}
                            className='long-richtext'
                        />
                    </div>

                    {/* OTHER DATA ABOUT THE TASK */}
                    {/* <TaskLinks
                        formInput={formInput}
                        setFormInput={setFormInput}
                    /> */}
                </div>

                {/* ACTIVITIES ON THE TASK */}
                <Activities taskData={selectedTaskData} trigger={trigger} setTrigger={setActivityTrigger} projectId={projectId} />
            </div>
            {isAdmin && (
                <div className='absolute left-[30px] bottom-[30px]'>
                    <IconButton
                        title='Delete'
                        icon={<AiTwotoneDelete size={20} color='#000' />}
                        className='h-[40px] w-[40px] bg-white'
                        onClick={deleteTaskHandler}
                    />
                </div>
            )}
        </ReactModal>
    )
}

export default TaskView;