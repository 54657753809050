import 'react-calendar/dist/Calendar.css';
import React, { useEffect, useState } from 'react';
import ReactCalendar from 'react-calendar';
import { useDispatch, useSelector } from 'react-redux';
import kitty from "../../assets/icons/kitty.svg";
import TextInput from '../../components/custom/textInput';
import Dropdown from '../../components/custom/dropdown';
import { DeleteRequest, GetRequest, PostRequest, PutRequest } from '../../utils/request';
import UpdateEvent from '../../popups/updateEvent';
import Delete from '../../popups/deleteEvent';
import Error from '../../components/custom/error';
import Layout from '../../layout';
import { eventActions } from '../../store/event-slice';
import { employeeActions } from '../../store/employee-slice';
import Button from '../../components/custom/button';

const Events = () => {
    const dispatch = useDispatch();
    const { accountInfo } = useSelector((state) => state?.account);
    const { events } = useSelector((state) => state?.event);
    const { departments } = useSelector((state) => state.employee);

    const [event, setEvent] = useState({
        selectedDepartments: { title: "All" },
        selectedDepartmentId: null,
        selectedDate: null,
        eventName: "",
        eventTime: ""
    });

    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);
    const [trigger, setTrigger] = useState(0);

    const role = accountInfo?.data.role.title.toLowerCase();

    const clickDateHandler = (date) => {
        setEvent(prevEvent => ({ ...prevEvent, selectedDate: new Date(date) }));
    };

    useEffect(() => {
        setLoading(true);
        if (accountInfo) {
            const id = accountInfo?.data?.role?.departmentId ? accountInfo?.data?.adminId : accountInfo?.data?._id;
            const url = `${process.env.REACT_APP_URL}/event/${id}` +
                (accountInfo?.data?.role?.departmentId ? `?departmentid=${accountInfo?.data?.role?.departmentId}` : '');
            GetRequest(url).then((response) => {
                const eventsWithDates = response?.data.map(event => ({
                    ...event,
                    eventDate: new Date(event.eventDate)
                }));
                dispatch(eventActions.setEvents(eventsWithDates));
                setLoading(false);
            }).catch((error) => {
                console.log(error, "fetch events error");
                setLoading(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trigger, accountInfo]);

    useEffect(() => {
        if (accountInfo) {
            GetRequest(`${process.env.REACT_APP_URL}/department/${accountInfo.data._id}`).then(response => {
                dispatch(employeeActions.setDepartments(response.data));
            }).catch((error) => {
                console.log("department error >", error);
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountInfo, trigger]);

    // Creating a new event
    const createEventHandler = () => {
        if (!event.eventName || !event.eventTime) {
            setError("Field must not be empty");
        } else {
            PostRequest(`${process.env.REACT_APP_URL}/event/${accountInfo?.data?._id}?departmentid=${event.selectedDepartmentId}`, {
                label: event.eventName,
                eventDate: event.selectedDate,
                eventTime: event.eventTime,
            }).then(() => {
                setEvent({
                    selectedDepartments: { title: "All" },
                    selectedDepartmentId: null,
                    selectedDate: null,
                    eventName: "",
                    eventTime: ""
                });
                setError(""); 
                setTrigger(prev => prev + 1);
            }).catch((error) => {
                console.log(error, "add event error");
            });
        }
    };

    // Updating an event
    const updateEventHandler = ({ id, label, eventTime, departmentId }) => {
        PutRequest(`${process.env.REACT_APP_URL}/event/${id}`, { label, eventTime, departmentId }).then(() => {
            setTrigger(prev => prev + 1);
        }).catch((error) => {
            console.log(error, "update event error");
        });
    };

    // Deleting an event
    const deleteEventHandler = (id) => {
        DeleteRequest(`${process.env.REACT_APP_URL}/event/${id}`).then(() => {
            setTrigger(prev => prev + 1);
        }).catch((error) => {
            console.log(error, "remove event error");
        });
    };

    const handleCreateEventKeyDown = (event) => {
        if (event.keyCode === 13 && !event.shiftKey) {
            event.preventDefault();
            createEventHandler()
        }
    };

    return (
        <Layout>
            <div className='pt-[30px] lg:pt-[50px] w-full' />
            <ReactCalendar
                value={event.selectedDate}
                onClickDay={clickDateHandler}
                tileClassName={({ date }) =>
                    event.selectedDate &&
                        date.toDateString() === event.selectedDate.toDateString()
                        ? "!bg-strawberry text-white"
                        : events?.length > 0 && events.some((event) => event.eventDate.toDateString() === date.toDateString())
                            ? "!bg-[#ffd1dc] text-black"
                            : ""
                }
                className="!w-full"
            />
            <div className="mt-[30px] py-[50px]">
                {role === "admin" && (
                    <div>
                        <div className='flex gap-[10px] item-center'>
                            <img
                                src={kitty}
                                alt="kitty"
                                className='max-w-[100px] w-full h-auto'
                            />
                            <h2 className='my-auto'>Create Event</h2>
                        </div>
                        {event.selectedDate ? (
                            <div className='mt-[30px] mx-auto max-w-[700px] w-full'>
                                <div>
                                    {error && <Error message={error} />}
                                    <p>Selected Date: {event.selectedDate.toDateString()}</p>
                                    <div className='flex max-md:flex-col gap-[10px] mt-[10px] w-[900px]'>
                                        <TextInput
                                            type="text"
                                            placeholder="Event Name"
                                            value={event.eventName}
                                            onChange={(e) => {
                                                setEvent(prevEvent => ({ ...prevEvent, eventName: e.target.value }));
                                                setError(""); 
                                            }}
                                            className='max-md:w-full w-[80%]'
                                            maxLength={30}
                                            onKeyDown={handleCreateEventKeyDown}
                                        />
                                        <TextInput
                                            type="time"
                                            placeholder="Event Time"
                                            value={event.eventTime}
                                            onChange={(e) => {
                                                setEvent(prevEvent => ({ ...prevEvent, eventTime: e.target.value }));
                                                setError(""); 
                                            }}
                                            className='w-full lg:w-[700px]'
                                            maxLength={30}
                                        />
                                        <Dropdown                                      
                                            value={event?.selectedDepartments?.title}
                                            onChange={(option) => {
                                                setEvent(prevEvent => ({
                                                    ...prevEvent,
                                                    selectedDepartments: option,
                                                    selectedDepartmentId: option?.title === "All" ? "" : option?._id
                                                }));
                                            }}
                                            options={[{ title: "All" }, ...departments]}
                                            className='bg-[#f9ffe3] w-full lg:w-[700px]'
                                        />
                                        <Button
                                            label='Add Event'
                                            onClick={createEventHandler}
                                            type='secondary'
                                            className='w-[10%] max-w-[200px]'
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <p className='mt-[30px] text-center'>---Select Date to Add Event---</p>
                        )}
                    </div>
                )}

                <div className={role === "admin" ? "mt-[50px]" : "mt-0"}>
                    <h2>All Events</h2>
                    {loading ? (
                        <div className="text-center mt-[30px]">Loading...</div>
                    ) : (
                        events?.length > 0 ? (
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-[30px] gap-[10px]">
                                {events.map((event) =>
                                    <div
                                        key={event._id}
                                        className="rounded-[8px] p-[20px] shadow-md bg-white"
                                    >
                                        <div className="flex justify-between items-center mb-[10px]">
                                            <span className="text-[#007bff] text-[12px]">
                                                {event.eventDate.toDateString()}  <span className="text-[#ef0145] text-[12px]" >({event?.eventTime})</span>
                                            </span>
                                            {role === "admin" && (
                                                <div className="flex">
                                                    <UpdateEvent
                                                        label={event.label}
                                                        id={event._id}
                                                        eventTime={event?.eventTime}
                                                        departmentId={event?.departmentId?._id}
                                                        departments={departments}
                                                        onClick={updateEventHandler}
                                                    />
                                                    <Delete label={`Are you sure you want to delete event "${event.label}"`} onClick={() => deleteEventHandler(event._id)} />
                                                </div>
                                            )}
                                        </div>
                                        <div className="pb-[10px]">
                                            <p className="text-[#800080] uppercase text-[18px] break-all">{event.label}</p>
                                            {event?.departmentId == null ?
                                                <p className="text-[#800080] uppercase text-[12px] break-all">(All)</p>
                                                :
                                                <p className="text-[#800080] uppercase text-[12px] break-all">({event?.departmentId?.title})</p>
                                            }
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <p className="mt-[30px] text-center">No Events Available</p>
                        )
                    )}
                </div>
            </div>
        </Layout>
    );
};

export default Events;
