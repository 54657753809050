import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import Toastify from '../components/custom/toastify';
import { WindowScroll } from '../utils/windowScroll';
import { CgMenuLeft } from "react-icons/cg";

const Header = () => {
    const isKey = localStorage.getItem("xios");
    const { pathname } = useLocation();
    const [drawer, setDrawer] = useState(false);
    return (
        <header className="text-black bg-[#c6eaf9] sticky top-0 py-[10px] z-[99999]">
            <Toastify />
            <div className='container mx-auto md:px-[40px] max-md:px-[15px]'>
                <div className='flex justify-between items-center gap-[10px]'>
                    <Link to={"/"}>
                        <img
                            src="/logo.webp"
                            alt="Mantaraa"
                            title='Mantaraa'
                            className="h-[55px]"
                        />
                    </Link>
                    <button onClick={() => setDrawer(prev => !prev)}>
                    <CgMenuLeft size={30} className="md:hidden cursor-pointer" /></button>
                    <div className={`${drawer ? 'max-md:left-[0%]' : ''}  max-md:-left-[100%] max-md:absolute max-md:top-0 max-md:h-[100vh] max-md:overflow-hidden max-md:overflow-y-auto max-md:w-full max-md:shadow-md max-md:bg-[linear-gradient(180deg,#fff_0%,#c5eaf9_100%)] max-md:max-w-[250px] max-md:duration-700 max-md:px-[15px] max-md:py-[24px] max-md:justify-between flex max-md:flex-col max-md:w-full md:gap-[40px] max-md:gap-[15px] md:items-center`} onClick={WindowScroll}>
                        <div className='flex max-md:flex-col max-md:w-full md:gap-[30px] max-md:gap-[15px] md:items-center max-md:mb-[30px]'>
                            {menuLinks.map((item, i) => (
                                <Link key={i} to={item.handle} className='relative text-[16px] font-medium'>
                                    {item.link}
                                    <span className={`absolute top-[30px] h-[1px] md:w-[70%] max-md:w-[45px] border border-black md:ml-[3px] ${pathname === item.handle ? "block" : "hidden"}`} />
                                </Link>
                            ))}
                        </div>
                        <div className='flex max-md:flex-col max-md:w-full md:gap-[30px] max-md:gap-[15px]'>
                            {isKey && isKey !== process.env.REACT_APP_SUPER_ADMIN_ID ? (
                                <>
                                    <Link to="/account" className='text-[16px] font-medium'>
                                        Account
                                    </Link>
                                </>
                            ) : (
                                <>
                                    <Link className='text-[16px] font-medium' to="/registration">
                                        Sign Up
                                    </Link>
                                    <Link className='text-[16px] font-medium' to="/login">
                                        Log In
                                    </Link>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

const menuLinks = [
    {
        link: "Our Team",
        handle: "/our-team"
    },
    {
        link: "Contact Us",
        handle: "/contact-us"
    }
]

export default Header;