import React, { useState } from 'react'
import { decrypt } from '../../utils/decryption';
import colorsList from "../../data/noteColors.json";
import { MdCheck } from "react-icons/md";
import { IoIosColorPalette } from 'react-icons/io';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { DeleteRequest, PutRequest } from '../../utils/request';
// import useDebounce from '../../hooks/useDebounce';
// import { encrypt } from '../../utils/encryption';
import { useDispatch, useSelector } from 'react-redux';
import { noteActions } from '../../store/note-slice';

const StickyNote = (props) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const { trigger } = useSelector(state => state.note);
    // const note = props?.note ? decrypt(props.note) : "";
    // const [updatedNote, setUpdatedNote] = useState(note);
    const [updatedColor, setUpdatedColor] = useState(props.color);

    function deleteHandler() {
        DeleteRequest(`${process.env.REACT_APP_URL}/notes/${props._id}`).then(response => {
            dispatch(noteActions.setTrigger(trigger + 1));
        }).catch(error => {
            console.log("note error >>>", error);
        });
    }
    function updateHandler(body) {
        PutRequest(`${process.env.REACT_APP_URL}/notes/${props._id}`, body).then(response => {
            dispatch(noteActions.setTrigger(trigger + 1));
        }).catch(error => {
            console.log("note error >>>", error);
        });
    }

    // const updateNoteHandler = useDebounce(() => updateHandler({ note: encrypt(updatedNote) }), 1000);

    return (
        <div className='sticky-note-book relative'>
            <div className='relative'>
                <div className='absolute right-[10px] bottom-[10px] flex gap-[5px] justify-end'>
                    <button
                        onClick={() => setShow(prev => !prev)}
                        className='bg-[#594cda] text-[#fff] text-center rounded-full p-2'
                    ><IoIosColorPalette size={18} /></button>
                    <button
                        onClick={() => deleteHandler(props._id)}
                        className='bg-[#eb427e] text-[#fff] text-center rounded-full p-2'
                    ><RiDeleteBin5Line size={18} /></button>
                </div>
                <textarea
                    value={decrypt(props.note)}
                    className={`px-[15px] pb-[45px] pt-[10px] w-full text-[#000] h-[220px] rounded outline-none placeholder-black`}
                    placeholder="write something....."
                    style={{ backgroundColor: props.color }}
                    // onChange={(e) => {
                    //     setUpdatedNote(e.target.value);
                    //     if (e.target.value.length > 2) {
                    //         updateNoteHandler();
                    //     }
                    // }}
                    disabled={true}
                ></textarea>
            </div>
            <div className={`flex-wrap gap-[5px] ${show ? "flex" : "hidden"}`}>
                {colorsList.map((color, i) => (
                    <div key={i} className="relative">
                        <button
                            className='w-[30px] h-[30px] rounded-full bg-[#ffe4c4] text-[0px]'
                            style={{ backgroundColor: color }}
                            onClick={() => {
                                setUpdatedColor(color);
                                updateHandler({ color: color })
                            }}
                        >bisque</button>
                        {color === updatedColor && (
                            <MdCheck size={20} className='absolute top-[5px] ml-[5px]' />
                        )}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default StickyNote;