import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ChatSocket from "../../socket/chat-socket";
import { GetRequest } from '../../utils/request';
import Layout from '../../layout';
import Loader from '../../components/custom/loader';
import StickyNote from '../../components/notes/stickyNote';
import CreateNote from '../../popups/createNote';
import { noteActions } from '../../store/note-slice';

const Notes = () => {
    const dispatch = useDispatch();
    const noteRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const { accountInfo } = useSelector((state) => state.account);
    const { chatTheme } = useSelector(state => state.ui);
    const { allNotes, trigger, pageCount, wasLastList } = useSelector((state) => state.note);

    const [receivedNote, setReceivedNote] = useState([]);
    const [indicator, setIndicator] = useState(false)

    ChatSocket.useMessageEvent(setReceivedNote, setIndicator, receivedNote, indicator);
    ChatSocket.useMyMessageEvent(setReceivedNote);
    ChatSocket.useFriendChatEvent();
    ChatSocket.useRemoveMessageEvent();

    const getNotes = (page) => {
        GetRequest(`${process.env.REACT_APP_URL}/notes?id=${accountInfo.data._id}&page=${page}&limit=9`).then(response => {
            if (response.data.length === 0) {
                dispatch(noteActions.setWasLastList(true))
            }
            if (page === 1) {
                dispatch(noteActions.setAllNotes(response.data))
                setReceivedNote([])
                dispatch(noteActions.setWasLastList(false))

            } else if (response.data.length > 0) {
                dispatch(noteActions.setAllNotes([...allNotes, ...response.data]))
                dispatch(noteActions.setWasLastList(false))
            }
            dispatch(noteActions.setPageCount(page + 1))
            setLoading(false)
        }).catch(error => {
            console.log("note fetching error >>>", error);
        });
    };

    const onScroll = () => {
        if (noteRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = noteRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 10) {
                if (!wasLastList) {
                    dispatch(noteActions.setPageCount(pageCount + 1));
                    getNotes(pageCount);
                }
            }
        }
    };

    useEffect(() => {
        dispatch(noteActions.setPageCount(1));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        if (accountInfo) {
            getNotes(pageCount);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountInfo]);

    useEffect(() => {
        if (accountInfo) {
            getNotes(1);
            // dispatch(noteActions.setPageCount(1))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trigger, accountInfo]);

    return (
        <Layout bgClass={chatTheme.className}>
            {loading && (
                <Loader className='bg-[#F5FFFA]' loaderCss="text-[#000]" />
            )}
            <div
                ref={noteRef} 
                onScroll={onScroll} 
                className='scrollar-width-zero w-full h-[calc(100vh_-_70px)] overflow-hidden overflow-y-auto pt-[20px] md:px-[10px] flex-end'
            >
                <div className='pt-[20px] pb-[50px] mb-[30px]'>
                    <div className='flex flex-wrap justify-between gap-[20px] mb-[30px]'>
                        <h2>Sticky Notes</h2>
                        <CreateNote />
                    </div>
                    <div className='grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 xl:gap-[30px] gap-[20px] overflow-hidden overflow-y-auto'>
                        {allNotes.length > 0 && allNotes.map((item, i) => (
                            <StickyNote
                                key={i}
                                {...item}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Notes;