import React from 'react';
import { IoMdAdd } from 'react-icons/io';
import { CiEdit } from "react-icons/ci";
import AddTask from '../../popups/addTask';
import MilestoneAction from '../../popups/milestoneAction';
import Avatar from '../custom/avatar';
import { useSelector } from 'react-redux';
import { Draggable, Droppable } from '@hello-pangea/dnd';
import getInitialLetter from '../../utils/getInitialLetter';
import { Link } from 'react-router-dom';

const Kanban = ({ projectId, setTrigger = () => { } }) => {
    const { activeProjectMilestones } = useSelector((state) => state.project);
    const { accountInfo } = useSelector(state => state.account);
    const isAdmin = accountInfo?.data.role?.title?.toLowerCase() === "admin";
    const { projectPermissions } = useSelector((state) => state.project);
    return (
        <div className="scrollar-width overflow-hidden overflow-x-auto md:h-[calc(100vh_-_160px)] max-md:h-[75vh] mt-[10px] pb-[30px]">
            <div className="flex grow shrink gap-[10px]">
                {activeProjectMilestones?.map((item, i) => (
                    <div key={i} className='w-[350px] grow-0 shrink-0'>
                        <Droppable droppableId={item._id.toString()} key={item._id.toString()}>
                            {(provided, snapshot) => (
                                <div 
                                    key={i} 
                                    className="p-[10px] bg-[#e8f0feaa] rounded-b-[20px] shadow-md"
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    <div className="group flex justify-between items-start mb-[10px] px-[10px] py-[8px]" style={{ backgroundColor: item.color }}>
                                        <h6 className="capitalize text-[14px] text-[#fff]">{item.title}</h6>
                                        {(isAdmin || accountInfo?.permissions?.updateProject) && (
                                            <MilestoneAction
                                                icon={<CiEdit className='group-hover:block hidden text-[#fff]' />}
                                                data={item}
                                                setTrigger={setTrigger}
                                            />
                                        )}
                                    </div>
                                    <div className='scrollar-width grid gap-[5px] overflow-hidden overflow-y-auto md:max-h-[calc(100vh_-_250px)] max-md:max-h-[calc(100vh_-_36vh)]'>
                                        {item.tasks?.length > 0 ? (
                                            item.tasks?.map((task, j) => {
                                                const assignees = [...task.assignees, ...task.departments];
                                                return(
                                                    <Draggable
                                                        draggableId={task._id.toString()}
                                                        index={j}
                                                        key={task._id.toString()}
                                                    >
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <Link
                                                                    to={`/task/${task._id}`}
                                                                    className={`border p-[10px] bg-white flex justify-between items-center gap-[10px] overflow-hidden min-h-[60px] w-full`}
                                                                >
                                                                    <h6 className="text-left text-[12px] capitalize truncate">{task.title}</h6>
                                                                    <div className="flex items-center" >
                                                                        {assignees.length > 0 && (
                                                                            <div className='flex items-center'>
                                                                                {assignees.slice(0, 3).map((item, i) => (
                                                                                    <>
                                                                                    {item.title ? (
                                                                                        <div title={item.title} className='h-[40px] w-[40px] rounded-full border border-[#aaa] flex justify-center items-center ml-[-5px] first:ml-0 bg-secondary select-none'>
                                                                                            {getInitialLetter(item.title)}
                                                                                        </div>
                                                                                    ):(
                                                                                        <Avatar
                                                                                            src={item.avatar}
                                                                                            alt={item.fullName}
                                                                                            title={item.fullName}
                                                                                            key={i}
                                                                                            noOnline
                                                                                            className={`ml-[-5px] first:ml-0`}
                                                                                        />
                                                                                    )}
                                                                                    </>
                                                                                ))}
                                                                                {assignees.length > 3 && (
                                                                                    <div className='h-[40px] w-[40px] flex justify-center items-center border border-[#aaa] rounded-full bg-white ml-[-10px] relative'>+{assignees.length - 3}</div>
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    </div>

                                                                </Link>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                )
                                            })
                                        ) : (
                                            <div className='text-[14px] text-center my-[10px]'>No Tasks</div>
                                        )}
                                        {provided.placeholder}

                                        {(projectPermissions?.add || isAdmin) && (
                                            <AddTask
                                                icon={<div className='flex items-center justify-center gap-[5px] py-[5px]'><IoMdAdd color="#000" size={16} /> <span className='text-[14px]'>Add Task</span></div>}
                                                projectId={projectId}
                                                milestones={activeProjectMilestones}
                                                item={item}
                                                setTrigger={setTrigger}
                                                className='mt-[3px]'
                                            />
                                        )}
                                    </div>
                                    {/* {provided.placeholder} */}
                                </div>
                            )}
                        </Droppable>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Kanban;