import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const SuperAdminRoute = () => {
    const key = localStorage.getItem("xios");
    return key ? (
        key === process.env.REACT_APP_SUPER_ADMIN_ID ? (
            <Outlet />
        ):(
            <Navigate to="/login" />
        )
    ) : (
        <Outlet />
    );
};

export default SuperAdminRoute;