import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    allNotes: [],
    trigger: 0,
    pageCount: 1,
    wasLastList: false
}

const noteSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        setAllNotes(state, action){
            state.allNotes = action.payload;
        },
        setTrigger(state, action){
            state.trigger = action.payload;
        },
        setPageCount(state, action){
            state.pageCount = action.payload;
        },
        setWasLastList(state, action){
            state.wasLastList = action.payload;
        }
    }
});

export const noteActions = noteSlice.actions;

export default noteSlice.reducer;