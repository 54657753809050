import React, { useEffect, useState } from 'react'
import { GetRequest, PutRequest } from '../../utils/request';
import { useDispatch, useSelector } from 'react-redux';
import { subscriptionActions } from '../../store/subscription-slice';
import { uiActions } from '../../store/ui-slice';

const UpdatePlan = () => {
    const dispatch = useDispatch();
    const [allPlans, setAllPlans] = useState([]);
    const { accountInfo } = useSelector((state) => state.account);
    const { planTrigger } = useSelector((state) => state.subscription);

    useEffect(() => {
        const config = {
            headers: {
                Authorization: `Bearer ${accountInfo?.token}`,
            },
        };
        GetRequest(`${process.env.REACT_APP_URL}/plan`, config).then((response) => {
            const filterPlan = response.data.filter(plan => plan.price !== 0)
            setAllPlans(filterPlan)
        }).catch((error) => {
            console.log("Errors fetching plan data", error);
        });
    },[accountInfo])
 
    function buyPlan(id){
        PutRequest(`${process.env.REACT_APP_URL}/subscription/${accountInfo.data._id}`, {plan: id}).then(response => {
           dispatch(subscriptionActions.setPlanTrigger(planTrigger+1))
        }).catch(error => {
            dispatch(uiActions.setToastify({
                message: error.data || "Something went wrong!",
                isSuccess: false
            }))
            console.log("update subscription error >>>", error);
        });
    }
    return (
        <div className='p-[30px] border w-full shadow-md inline-flex flex-col relative bg-white mt-[20px] lg:mt-[30px] overflow-hidden'>
            <h4>Buy Plan</h4>
            <div className='mt-[30px]'>
                {allPlans.length > 0 ? (
                    <div className='grid gap-[10px]'>
                        {allPlans.map((item, i) => (
                            <div key={i} className='border p-[10px] flex items-center justify-between'>
                                <div>
                                    <h5>{item.title}</h5>
                                    <div>
                                        <span className='text-[20px]'>INR {item.price}</span> / {item.days} days
                                    </div>
                                </div>
                                <button onClick={() => buyPlan(item._id)} className='h-[35px] px-[20px] bg-[#aaa]'>Buy</button>
                            </div>
                        ))}
                    </div>
                ):(
                    <p>Loading...</p>
                )}
            </div>
        </div>
    )
}

export default UpdatePlan;