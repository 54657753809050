import React from 'react'
import { formatDateAndTime } from '../../utils/formatTimestamp';

const PlanHistory = ({data}) => {
    return (
        <div className='p-[30px] border w-full shadow-md inline-flex flex-col relative bg-white mt-[20px] lg:mt-[30px] overflow-hidden'>
            <h4>History</h4>
            <table className='mt-[20px] relative'>
                <thead>
                    <tr className='bg-[#dcdcdc] py-[120px]'>
                        {columns.map((col, i) => (
                            <td
                                key={i}
                                style={{ width: col.width, textAlign: col.textAlign}}
                                className='!font-bold !text-[14px] text-left px-[10px] py-[10px]'
                            >{col.header}</td>
                        ))}
                        <td className='text-left'></td>
                    </tr>
                </thead>
                {data.length > 0 ? (
                    <tbody>
                        {data.map((item, i) => (
                            <tr key={i} className={`bg-[#f8f8ff] border-b`}>
                                <td className='text-left text-[14px] px-[10px] py-[5px]'>{item.plan.title || "----"}</td>
                                <td className='text-left text-[14px] px-[10px] py-[5px] capitalize'>
                                    {!item.isActive && !isDateExpired(item.expiryOn) ? "---" : formatDateAndTime(item.startedOn) }
                                </td>
                                <td className='text-left text-[14px] px-[10px] py-[5px] capitalize'>
                                    {!item.isActive && !isDateExpired(item.expiryOn) ? "---" : formatDateAndTime(item.expiryOn) }
                                </td>
                                <td className='text-left text-[14px] px-[10px] py-[5px]'>{!item.isActive && isDateExpired(item.expiryOn) ? "Yes" : "No"}</td>
                                <td className={`text-right text-[14px] px-[10px] py-[5px] font-semibold ${item.isActive ? "text-[#008000]" : "text-[#FF0000]"} `}>{item.isActive ? "Yes" : "No"}</td>
                            </tr>
                        ))}
                    </tbody>
                ):(
                    <p className='text-center w-full absolute top-[70px]'>No History!</p>
                )}
            </table>
        </div>
    )
}

const isDateExpired = (dateString) => {
    const inputDate = new Date(dateString);
    const currentDate = new Date();

    // Compare only the date part, ignoring time
    const isExpired = inputDate < currentDate;

    return isExpired;
};

const columns = [
    { 
        header: 'Plan', 
        width: '10%',
        textAlign: "left"
    },
    { 
        header: 'From', 
        width: '30%',
        textAlign: "left"
    },
    { 
        header: 'To', 
        width: '30%',
        textAlign: "left"
    },
    { 
        header: 'Expired', 
        width: '15%',
        textAlign: "left"
    },
    { 
        header: 'Active', 
        width: '15%',
        textAlign: "right"
    },
];

export default PlanHistory;