import React from 'react';
import Layout from '../../layout';

const Logs = () => {
    return (
        <Layout>
            <div className='pt-[30px] lg:pt-[50px] pb-[50px]'>
                <div className='flex flex-col md:flex-row justify-between items-center gap-[30px]'>
                    <h3>Logs</h3>
                </div>
                <div>
                    {dummyData.map((item, i) => (
                        <div>
                            
                        </div>
                    ))}
                </div>
                <table className='mt-[20px] relative'>
                    <thead>
                        <tr className='bg-[#dcdcdc] py-[120px]'>
                            {columns.map((col, i) => (
                                <td
                                    key={i}
                                    style={{ width: col.width, textAlign: col.textAlign}}
                                    className='!font-bold !text-[14px] text-left px-[10px] py-[10px]'
                                >{col.header}</td>
                            ))}
                            <td className='text-left'></td>
                        </tr>
                    </thead>
                    {dummyData.length > 0 ? (
                        <tbody>
                            {dummyData.map((item, i) => (
                                <tr key={i} className={`bg-[#f8f8ff] border-b`}>
                                    <td className='text-left text-[14px] px-[10px] py-[5px]'>{item.timestamp || "----"}</td>
                                    <td className='text-left text-[14px] px-[10px] py-[5px] capitalize'>{item.user || "----"}</td>
                                    <td className='text-left text-[14px] px-[10px] py-[5px]'>{item.comment || "----"}</td>
                                    <td className='text-right text-[14px] px-[10px] py-[5px]'>{item.type || "----"}</td>
                                </tr>
                            ))}
                        </tbody>
                    ):(
                        <p className='text-center w-full absolute top-[70px]'>No Logs!</p>
                    )}
                </table>
            </div>
        </Layout>
    )
}

const dummyData = [
    {
        timestamp: "9 Sept, 20204 at 12:00 AM",
        user: "Priyanka",
        comment: "updated the avatar",
        type: "account"
    },
    {
        timestamp: "9 Sept, 20204 at 12:00 AM",
        user: "Hiteshi",
        comment: "updates phone number",
        type: "account"
    },
    {
        timestamp: "9 Sept, 20204 at 12:00 AM",
        user: "Tanu",
        comment: "updated the password",
        type: "account"
    },
    {
        timestamp: "9 Sept, 20204 at 12:00 AM",
        user: "Jyoti",
        comment: "commented on task - Subscription",
        type: "task"
    },
    {
        timestamp: "9 Sept, 20204 at 12:00 AM",
        user: "Priyanka",
        comment: "changes the layout theme",
        type: "account"
    },
    {
        timestamp: "9 Sept, 20204 at 12:00 AM",
        user: "Priyanka",
        comment: "updated the avatar",
        type: "account"
    },
    {
        timestamp: "9 Sept, 20204 at 12:00 AM",
        user: "Hiteshi",
        comment: "updated the avatar",
        type: "account"
    }
]

const columns = [
    { 
        header: 'Timestamp', 
        width: '30%',
        textAlign: "left"
    },
    { 
        header: 'User', 
        width: '20%',
        textAlign: "left"
    },
    { 
        header: 'Comment', 
        width: '40%',
        textAlign: "left"
    },
    { 
        header: 'Type', 
        width: '10%',
        textAlign: "right"
    },
];

export default Logs;