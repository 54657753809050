import React, { useState } from 'react';
import Loader from '../components/custom/loader';
import Header from '../layout/header';
import Footer from '../layout/footer';
import { WindowScroll } from '../utils/windowScroll';
import ResetPassword from '../components/account/resetPassword';
import VerifyOtp from '../components/account/verifyOtp';
import UpdatePassword from '../components/account/updatePassword';

const ForgotPassword = () => {
    const [disabled, setDisabled] = useState(false);
    const [page, setPage] = useState(0);
    const [received, setReceived] = useState({
        userId: "",
        otp: ""
    })

    const renderComponent = [
        <ResetPassword 
            setDisabled={setDisabled} 
            setPage={setPage}
            setReceived={setReceived}
        />,
        <VerifyOtp 
            setDisabled={setDisabled} 
            setPage={setPage}
            received={received}
        />,
        <UpdatePassword 
            setDisabled={setDisabled}  
            setPage={setPage}
            received={received}
        />
    ]

    return (
        <>
            <Header />
            <div className="md:pt-[90px] md:pb-[20px] max-md:py-[50px] bg-[linear-gradient(0deg,#fff_0%,#c5eaf9_100%)]">
                <div className="container mx-auto md:px-[40px] max-md:px-[15px]">
                    <div className="max-w-[500px] px-[10px] md:px-[30px] py-[40px] relative bg-white mx-auto w-full rounded-[10px] border" onClick={WindowScroll}>
                        {disabled && (
                            <Loader />
                        )}
                        {renderComponent[page]}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default ForgotPassword;