import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    accountInfo: null,
    projects: [],
    companyData: null,
    meOnline: false
}

const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        setAccountInfo(state, action) {
            state.accountInfo = action.payload;
        },
        setProjects(state, action) {
            state.projects = action.payload;
        },
        setCompanyData(state, action) {
            state.companyData = action.payload;
        },
        logOut() {
            localStorage.removeItem("xios");
            return initialState;
        },
        setMeOnline(state, action) {
            state.meOnline = action.payload;
        },
    }
});

export const accountActions = accountSlice.actions;

export default accountSlice.reducer;