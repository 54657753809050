import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currentPlan: null,
    nextPlan: null,
    planTrigger: 0,
    allPlans: []
}

const subscriptionSlice = createSlice({
    name: 'subscription',
    initialState,
    reducers: {
        setNextPlan(state, action) {
            state.nextPlan = action.payload;
        },
        setCurrentPlan(state, action) {
            state.currentPlan = action.payload;
        },
        setPlanTrigger(state, action) {
            state.planTrigger = action.payload;
        },
        setAllPlans(state, action) {
            state.allPlans = action.payload;
        }
    }
});

export const subscriptionActions = subscriptionSlice.actions;

export default subscriptionSlice.reducer;