import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uiActions } from '../../store/ui-slice';

const Toastify = ({ className = "" }) => {
    const dispatch = useDispatch();
    const { toastify } = useSelector((state) => state.ui);

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(uiActions.setToastify({
                message: "",
                isSuccess: false
            }));
        }, 2000);
        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[toastify])

    return (
        <div className={`top-[50px] left-1/2 right-auto bottom-auto mr-[-50%] transform translate-x-[-50%] translate-y-[-50%] shadow-red absolute bg-white px-[20px] py-[15px] shadow-lg z-[9999] rounded-[10px] max-w-[600px] ${toastify.isSuccess ? "text-[#008000]" : "text-[#FF0000]"} ${toastify.message ? "block" : "hidden"} ${className}`}>{toastify.message}</div>
    )
}

export default Toastify;