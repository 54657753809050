import React, { useState } from 'react'
import { FaPlus } from 'react-icons/fa6';
import ReactModal from '.';
import Loader from '../components/custom/loader';
import { IoIosColorPalette } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { PostRequest } from '../utils/request';
import { chatActions } from '../store/chat-slice';
import { encrypt } from '../utils/encryption';
import ChatSocket from "../socket/chat-socket";
import Button from '../components/custom/button';
import colorsList from "../data/noteColors.json";
import { noteActions } from '../store/note-slice';

const CreateNote = () => {
    const dispatch = useDispatch(); 
    const { accountInfo } = useSelector((state) => state.account);
    const {trigger} = useSelector((state) => state.note )
    const [ open, setOpen ] = useState(false);
    const [ disabled, setDisabled ] = useState(false);
    const [ note, setNote ] = useState("");
    const [ colorKey, setColorKey ] = useState(0);

    function noteHandler() { 
        if (note?.trim() !== '') {
            const formData = new FormData();
            formData.append('note', encrypt(note));
            formData.append('color', colorsList[colorKey]);
            PostRequest(`${process.env.REACT_APP_URL}/notes/${accountInfo.data._id}`, formData).then(response => {
                dispatch(chatActions.setReplyData(null));
                dispatch(chatActions.setIsSearched(true))
                ChatSocket.emitNewMessage(response.data.note, accountInfo?.data);
                dispatch(noteActions.setTrigger(trigger + 1))
                closeHandler()
            }).catch(error => {
                console.log("note error >>>", error);
            });
        }
    }
    const handleKeyDown = (event) => {
        if (event.keyCode === 13 && !event.shiftKey) {
            event.preventDefault();
            if (note.length > 0) {
                noteHandler();
            }
        }
    };

    function closeHandler(){
        setOpen(false);
        setDisabled(false);
        setNote("")
    }

    function colorHandler(){
        if (colorKey === (colorsList.length - 1)){
            setColorKey(0)
        }else{
            setColorKey(colorKey+1)
        }
    }

    return (
        <>
        <button 
            onClick={() => setOpen(true)}
            className='inline-flex justify-center items-center gap-[5px] border border-[#594cda] text-[#fff] bg-[#594cda] hover:text-[#594cda] hover:bg-[#fff] md:px-[10px] md:py-[5px] max-md:px-[10px] max-md:py-[10px] text-[16px] leading-[27px] font-medium md:w-[150px] max-md:w-[150px] text-center rounded-full'><FaPlus size={18}
        /> New Note</button>
        <ReactModal open={open} close={closeHandler} maxWidth="500px">
            {disabled && <Loader />}
            <div className='sticky-note-book relative '>
                <div className='relative'>
                    <div className='absolute right-[10px] bottom-[10px] flex gap-[5px] justify-end'>
                        <button 
                            onClick={colorHandler}
                            className='bg-[#594cda] text-[#fff] text-center rounded-full p-2'
                        ><IoIosColorPalette size={18} /></button>
                    </div>
                    <textarea 
                        className="px-[15px] pt-[15px] pb-[45px] w-full text-[#000] h-[220px] rounded outline-none placeholder-black" 
                        placeholder="Write something....."
                        maxLength={500}
                        onChange={(e) => setNote(e.target.value)}
                        value={note}
                        onKeyDown={handleKeyDown}
                        style={{backgroundColor: colorsList[colorKey]}}
                    ></textarea>
                </div>
                <div className='flex justify-end items-center mt-[5px]'>
                    <Button 
                        label='Done'
                        type='secondary'
                        className='!w-[100px]'
                        onClick={noteHandler}
                    />
                </div>
            </div>
        </ReactModal>
        </>
    )
}

export default CreateNote;