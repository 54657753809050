import React, { useState, useEffect } from "react";
import ReactModal from ".";
import Avatar from "../components/custom/avatar";
import { useDispatch, useSelector } from "react-redux";
import { MdAlternateEmail } from "react-icons/md";
import { employeeActions } from "../store/employee-slice";
import { taskActions } from "../store/task-slice";
import Search from "../components/custom/search";
import { PutRequest, GetRequest } from "../utils/request";
import { GrGroup } from "react-icons/gr";
import TaskSocket from '../socket/task-socket';

const AddAssignees = () => {
    const dispatch = useDispatch();
    const { accountInfo } = useSelector((state) => state.account);
    const { employees, departments } = useSelector((state) => state.employee);
    const { assigneesModalOpen, taskAssignees, selectedTaskData, taskDepartments } = useSelector((state) => state.task);

    const [assignees, setAssignees] = useState(taskAssignees);
    const [allDepartments, setAllDepartments] = useState(taskDepartments);
    const [selected, setSelected] = useState(sideLinks[0].title);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [searchKey, setSearchKey] = useState("");

    function handleClose() {
        dispatch(taskActions.setAssigneesModalOpen(null));
        setSearchKey("")
    }

    function updateAssigneeHandler(body) {
        body.updatedBy = accountInfo?.data.fullName;
        PutRequest(`${process.env.REACT_APP_URL}/task/assignee/${selectedTaskData._id}`, body).then(response => {
            TaskSocket.emitTaskUpdate(response.data.projectId, response.data, response.data.assignees, "assignee updated", body.assignee, accountInfo?.data.fullName)
            // setTrigger(prev => prev + 1);
        }).catch(error => {
            console.log("error updating milestone", error)
        })
    };

    useEffect(() => {
        if (accountInfo) {
            GetRequest(`${process.env.REACT_APP_URL}/department/${accountInfo.data._id}`).then(response => {
                dispatch(employeeActions.setDepartments(response.data));
            }).catch((error) => {
                console.log("department error >", error);
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountInfo])

    useEffect(() => {
        if (searchKey.length > 0){
            const filtered = employees.filter(index => {
                return index.fullName.toLowerCase().includes(searchKey.toLowerCase())
            });
            setFilteredUsers(filtered)
        }else{
            setFilteredUsers(employees)
        }
    },[searchKey, employees])
    
    return (
        <ReactModal open={assigneesModalOpen} close={handleClose} maxWidth="700px" padding='20px' margin='10px' >
            <div>
                <div className='flex justify-between items-center py-[10px]'>
                    <h3>Assignees</h3>
                    {selected === sideLinks[0].title && (
                        <Search
                            placeholder='Search'
                            className="max-w-[250px]"
                            value={searchKey}
                            onChange={(e) => setSearchKey(e.target.value)}
                        />
                    )}
                </div>
                <hr />
                <div className="flex flex-row w-full min-h-[300px]">
                    <div className="border-r border-r-black w-[200px]">
                        {sideLinks.map((item, i) => (
                            <button
                                key={i}
                                className={`w-full px-[10px] py-[15px] flex justify-between items-center gap-[10px] border-b ${selected === item.title ? 'bg-slate-300' : ''}`}
                                onClick={() => {
                                    setSelected(item.title);
                                    setSearchKey("")
                                }}
                            >
                                <div className="capitalize flex items-center">
                                    {item.icon} {item.title}
                                </div>
                            </button>
                        ))}
                    </div>

                    <div className={`w-full max-h-[300px] min-w-[200px] bg-white py-[10px] px-[5px] rounded-[10px] z-[10] overflow-hidden overflow-y-auto flex flex-col`}>
                        {selected === sideLinks[1].title ? (
                            departments?.length > 0 && departments.map((item, i) => (
                                <div
                                    key={i}
                                    className="flex justify-between gap-[10px] cursor-pointer py-[5px] px-[10px] items-center bg-hover w-full"
                                >
                                    <div className="flex gap-[10px] items-center">
                                        <span className="text-[14px] capitalize select-none">{item?.title}</span>
                                    </div>
                                    <input
                                        type="checkbox"
                                        checked={allDepartments.map(department => department?._id).includes(item?._id)}
                                        onChange={() => {
                                            updateAssigneeHandler({department: item?._id})
                                            if (!allDepartments.map(department => department?._id).includes(item?._id)) {
                                                setAllDepartments([...allDepartments, item]);
                                            } else {
                                                setAllDepartments(allDepartments.filter(department => department?._id !== item?._id));
                                            }
                                        }}
                                        className="ml-auto"
                                    />
                                </div>
                            ))
                        ):(
                            filteredUsers?.length > 0 ? (
                                filteredUsers.map((item, i) => (
                                    <div
                                        key={i}
                                        className="flex justify-between gap-[10px] py-[5px] px-[10px] items-center bg-hover w-full"
                                    >
                                        <div className="flex gap-[10px] items-center">
                                            <Avatar
                                                src={item?.avatar}
                                                alt={item?.fullName}
                                                noOnline
                                                className={`${assignees.some(assignee => assignee._id === item._id) && "!border-[2px] border-black"}`}
                                            />
                                            <span className="text-[14px] capitalize select-none">{item?.fullName}</span>
                                        </div>
                                        <input
                                            type="checkbox"
                                            checked={assignees.map(assignee => assignee?._id).includes(item?._id)}
                                            onChange={() => {
                                                updateAssigneeHandler({assignee: item?._id})
                                                if (!assignees.map(assignee => assignee?._id).includes(item?._id)) {
                                                    setAssignees([...assignees, item]);
                                                } else {
                                                    setAssignees(assignees.filter(assignee => assignee?._id !== item?._id));
                                                }
                                            }}
                                            className="ml-auto"
                                        />
                                    </div>
                                ))
                            ):(
                                <p className="w-full text-center mt-[30px]">No Result Found!</p>
                            )
                        )}
                    </div>
                </div>
                <button onClick={handleClose} className='flex px-[35px] py-[5px] bg-pink-200 bg-hover mx-auto rounded-md mt-5 '>
                    Done
                </button>
            </div>
        </ReactModal >
    )
};

const sideLinks = [
    {
        title: "Users",
        icon: <MdAlternateEmail size={16} className="mr-[10px]" />
    },
    {
        title: "Departments",
        icon: <GrGroup size={15} className="mr-[10px]" />
    }
]

export default AddAssignees;