import React, { useState } from 'react'
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import Button from '../custom/button';
import Label from '../custom/label';
import TextInput from '../custom/textInput';
import Error from '../custom/error';
import { useDispatch } from 'react-redux';
import { checkEmptyFields, validatePassword } from '../../utils/formValidation';
import { PutRequest } from '../../utils/request';
import { uiActions } from '../../store/ui-slice';
import { useNavigate } from 'react-router-dom';

const UpdatePassword = ({setPage=()=>{}, setDisabled=()=>{}, received={}}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [error, setError] = useState("");
    const [formInput, setFormInput] = useState({
        newPass: "",
        confirmNewPass: ""
    });
    const [show, setShow] = useState({
        newPass: false,
        confirmNewPass: false
    })

    const onChangeHandler = (e) => {
        const { name, value } = e.target;
        const cleanedValue = value.replace(" ", '');
        setFormInput((prevState) => ({
            ...prevState,
            [name]: cleanedValue
        }));
        setError("");
    };

    function resetPasswordHandler() {
        setDisabled(true);
        setError("")
        if (checkEmptyFields(formInput)) {
            setError("Fields must not be empty!")
            setDisabled(false);
        } else if (!validatePassword(formInput.newPass)) {
            setError("New Password must contain atleast one uppercase, one lowercase, one number and one special character!")
            setDisabled(false);
        } else if (formInput.newPass !== formInput.confirmNewPass) {
            setError("Confirm password should match new password!")
            setDisabled(false);
        } else {
            PutRequest(`${process.env.REACT_APP_URL}/user/resetPassword`, {
                newPassword: formInput.newPass,
                userId: received.userId
            }).then((response) => {
                dispatch(uiActions.setToastify({
                    message: "Password updated successfully!",
                    isSuccess: true
                }));
                const timer = setTimeout(() => {
                    navigate('/login')
                    setPage(0)
                }, 3000);
                return () => clearTimeout(timer);
            }).catch((error) => {
                setError(error.data)
                setDisabled(false);
            });
        }
    }

    const handleKeyDown = (event) => {
        if (event.keyCode === 13 && !event.shiftKey) {
            event.preventDefault();
            resetPasswordHandler();
        }
    };

    return (
        <div>
            <p className='text-success'>Verified successfully</p>
            <h2 className='mb-[20px] text-center md:text-left'>Reset Password</h2>
            <Error message={error} />
            <div className="mb-[15px] relative">
                <Label
                    title='New Password'
                    htmlFor="new-password"
                />
                <TextInput
                    id='new-password'
                    name="newPass"
                    type={show.newPass ? "text" : "password"}
                    value={formInput.newPass}
                    onChange={onChangeHandler}
                    handleKeyDown={handleKeyDown}
                />
                {show.newPass ? (
                    <button onClick={() => setShow(prevState => ({ ...prevState, newPass: false }))} className='absolute right-[10px] top-[68%] translate-y-[-50%]' >
                        <FaRegEyeSlash size={15} />
                    </button>
                ) : (
                    <button onClick={() => setShow(prevState => ({ ...prevState, newPass: true }))} className='absolute right-[10px] top-[68%] translate-y-[-50%]' >
                        <FaRegEye size={15} />
                    </button>
                )}
            </div>
            <div className="mb-[15px] relative">
                <Label
                    title='Confirm Password'
                    htmlFor="confirm-password"
                />
                <TextInput
                    id='confrim-password'
                    name="confirmNewPass"
                    type={show.confirmNewPass ? "text" : "password"}
                    value={formInput.confirmNewPass}
                    onChange={onChangeHandler}
                    handleKeyDown={handleKeyDown}
                />
                {show.confirmNewPass ? (
                    <button onClick={() => setShow(prevState => ({ ...prevState, confirmNewPass: false }))} className='absolute right-[10px] top-[68%] translate-y-[-50%]' >
                        <FaRegEyeSlash size={15} />
                    </button>
                ) : (
                    <button onClick={() => setShow(prevState => ({ ...prevState, confirmNewPass: true }))} className='absolute right-[10px] top-[68%] translate-y-[-50%]' >
                        <FaRegEye size={15} />
                    </button>
                )}
            </div>
            <Button
                label={`Reset Password`}
                onClick={resetPasswordHandler}
            />
        </div>
    )
}

export default UpdatePassword;