import React from 'react';
import Layout from '../../layout';

const Report = () => {
    return (
        <Layout>
            <div className='pt-[30px] lg:pt-[50px] pb-[50px]'>
                <div className='flex flex-col md:flex-row justify-between items-center gap-[30px] mb-[30px]'>
                    <h3>Report</h3>
                </div>
                <div>
                    <p>Having trouble? Want to report an issue?</p>
                    <p>Fill out the form below and let us know what are you facing?</p>
                </div>
            </div>
        </Layout>
    )
}

export default Report;