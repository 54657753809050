// CONVERTS DATE FROM { 2024-10-20T00:00:00.000Z } TO { 20 OCTOBER 2024 }
export function formatDate(inputDate) {
    if (!inputDate) return '---';

    const date = new Date(inputDate);
    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const formattedDate = `${day} ${month} ${year}`;

    return formattedDate;
}

// CONVERTS DATE FROM { 2024-08-20T00:00:00.000Z } TO { 2024-08-20 }
export function formatDateForCompare(inputDate) {
    const date = new Date(inputDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
}

//NOT USED
export function formatActivityDate(inputDate) {
    const date = new Date(inputDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    const formattedDate = `${day}-${month}-${year}`;

    return formattedDate;
}

// CONVERTS DATE FROM { 2024-07-27T09:29:00.000Z } TO { 2024-07-27T14:59 }
export function formatDateForInput(date) {
    if (!date) return '';
    const d = new Date(date);
    const pad = (num) => num.toString().padStart(2, '0');
    return `${d.getFullYear()}-${pad(d.getMonth() + 1)}-${pad(d.getDate())}T${pad(d.getHours())}:${pad(d.getMinutes())}`;
};

// CONVERTS DATE FROM { 2024-10-16T10:37:24.443Z } TO { October 16, 2024 at 4:07 PM }
export function formatDateAndTime(date) {
    if (!date) return "---";

    const parsedDate = new Date(date);
    const options = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    };

    return parsedDate.toLocaleDateString('en-US', options);
}

// CONVERTS DATE FROM { ? } TO { ? }
export function formatCreatedAt(timestamp, type) {
    if (!timestamp) return ''; 
    if (type === "time"){
        const options = {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
        };
        const dateTimeFormat = new Intl.DateTimeFormat('en-US', options);
        return dateTimeFormat.format(new Date(timestamp));
    }
    if (type === "day"){
        const options = {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        };
        const dateTimeFormat = new Intl.DateTimeFormat('en-US', options);
        return dateTimeFormat.format(new Date(timestamp));
    }
    if (type === "both"){
        const options = {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false
        };
        const dateTimeFormat = new Intl.DateTimeFormat('en-US', options);
        return dateTimeFormat.format(new Date(timestamp));
    }
};

export const formatDateForMessage = (date) => {
    const year = date?.getFullYear();
    const month = String(date?.getMonth() + 1).padStart(2, '0');
    const day = String(date?.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

export const isToday = (inputDate) => {
    const today = new Date();
    return formatDateForMessage(today) === formatDateForMessage(inputDate);
}

export const isYesterday = (inputDate) => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    return formatDateForMessage(yesterday) === formatDateForMessage(inputDate);
}

export const isTimeout = (date) => {
    const now = new Date();
    const inputDate = new Date(date); // Parse the input date

    const timeDifference = now.getTime() - inputDate.getTime(); // Difference in milliseconds
    const fiveMinutesInMs = 5 * 60 * 1000; // 5 minutes in milliseconds
    
    return timeDifference >= fiveMinutesInMs;
}

export const timestampLabel = (date) => {
    const dateObj = new Date(date);

    // Get hours and minutes
    let hours = dateObj.getHours();
    let minutes = dateObj.getMinutes();

    // Add leading zero to minutes if necessary
    if (minutes < 10) {
        minutes = '0' + minutes;
    }

    // Format the time as "HH:MM"
    const time = `${hours}:${minutes}`;

    let label;
    if (isToday(dateObj)) {
        label = `Today, ${time}`;
    } else if (isYesterday(dateObj)) {
        label = `Yesterday, ${time}`;
    } else {
        label = formatCreatedAt(date, "day");
    }
    return label;
}