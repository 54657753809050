import { decrypt } from '../../utils/decryption';

const useshowNotification = (data, friend) => {
    const tag = `mantaraa-${data?.message?._id}`;
    const notification = new Notification(friend?.fullName, {
        body: data?.image ? "Sent you visuals" : data?.message ? decrypt(data?.message) : "",
        icon: friend?.avatar,
        badge: friend?.avatar,
        vibrate: [200, 100, 200],
        dir: "rtl",
        renotify: true,
        tag: tag,
    });

    notification.onclick = () => {
        window.focus();
        window.location.href = `http://localhost:3021/chat/${data?.conversationId}`;
    };
};

const useshowTaskNotification = (data) => {
    console.log("ddd", data)
    const tag = `mantaraa-${data?.taskId}`;
    const notification = new Notification(data?.project, {
        body: `${data.by} ${data.msg}`,
        icon: "/logo.webp",
        badge: "/logo.webp",
        vibrate: [200, 100, 200],
        dir: "rtl",
        renotify: true,
        tag: tag,
    });

    notification.onclick = () => {
        window.focus();
        window.location.href = `https://demo.mantaraa.com/tasks/${data.projectId}`;
    };
};

const Notifications = {
    useshowNotification,
    useshowTaskNotification
}

export default Notifications;