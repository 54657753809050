import React, { useEffect, useState } from "react";
import { TbLayoutKanban } from "react-icons/tb";
import { MdBrowserNotSupported, MdOutlineChecklist } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { DeleteRequest, GetRequest, PutRequest } from "../../utils/request";
import Layout from "../../layout";
import AddMilestone from "../../popups/addMilestone";
import Loader from "../../components/custom/loader";
import Kanban from "../../components/task/kanban";
import List from "../../components/task/list";
import { taskActions } from "../../store/task-slice";
import TaskView from "../../popups/taskView";
import TaskSocket from '../../socket/task-socket';
import { DragDropContext } from '@hello-pangea/dnd';
import ReportGenerator from "../../popups/reportGenerator";
import { useParams } from 'react-router-dom'; 

const Tasks = () => {
    const { taskProjectId } = useParams();
    const dispatch = useDispatch();
    const { accountInfo } = useSelector((state) => state.account);
    const { projects, activeProject, milestones, layout, projectTrigger, selectedTaskData } = useSelector((state) => state.task);
    const [taskTrigger, setTaskTrigger] = useState(0);
    const [loader, setLoader] = useState(false);
    const isAdmin = accountInfo?.data.role?.title?.toLowerCase() === "admin";

    TaskSocket.useTaskAdded()
    TaskSocket.useTaskUpdate(taskTrigger, setTaskTrigger)

    useEffect(() => {
        if (taskProjectId) {
            GetRequest(`${process.env.REACT_APP_URL}/project/detail/${taskProjectId}`).then((response) => {
                dispatch(taskActions.setActiveProject(response.data));
                setLoader(false);
            }).catch((error) => {
                console.error("Error fetching user details:", error);
            });
            GetRequest(`${process.env.REACT_APP_URL}/task/${taskProjectId}`).then((response) => {
                dispatch(taskActions.setMilestones(response.data));
                setLoader(false);
            }).catch((error) => {
                console.error("Error fetching user details:", error);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps   
    }, [taskProjectId]);

    useEffect(() => {
        if (activeProject) {
            if (!projects.some(project => project._id === activeProject._id) && projects.length > 0) {
                dispatch(taskActions.setActiveProject(projects[0]));
            } else {
                GetRequest(`${process.env.REACT_APP_URL}/task/${activeProject?._id}`).then((response) => {
                    dispatch(taskActions.setMilestones(response.data));
                    setLoader(false);
                }).catch((error) => {
                    console.error("Error fetching user details:", error);
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps    
    }, [activeProject, taskTrigger, projectTrigger, projects])

    function deleteTaskHandler(id) {
        DeleteRequest(`${process.env.REACT_APP_URL}/task/${id}`).then((response) => {
            setTaskTrigger(prev => prev + 1)
        }).catch((error) => {
            console.error("Error deleteing task", error);
        });
    }

    const onDragEnd = (result) => {
        const { source, destination } = result;

        // If there's no destination, do nothing
        if (!destination) return;

        // If the item is dropped in the same position, do nothing
        if (destination.droppableId === source.droppableId) return;
        // if (destination.droppableId === source.droppableId && destination.index === source.index) return;

        // Get the source milestone and task
        const sourceMilestoneIndex = milestones.findIndex(milestone => milestone._id === source.droppableId);
        const sourceMilestone = milestones[sourceMilestoneIndex];
        const task = sourceMilestone.tasks[source.index];

        // Remove task from source milestone
        const newSourceTasks = Array.from(sourceMilestone.tasks);
        newSourceTasks.splice(source.index, 1);

        const updatedSourceMilestone = {
            ...sourceMilestone,
            tasks: newSourceTasks,
        };

        // Get the destination milestone
        const destinationMilestoneIndex = milestones.findIndex(milestone => milestone._id === destination.droppableId);
        const destinationMilestone = milestones[destinationMilestoneIndex];

        // Add task to the destination milestone at the correct position
        const newDestinationTasks = Array.from(destinationMilestone.tasks);
        newDestinationTasks.splice(destination.index, 0, task);

        const updatedDestinationMilestone = {
            ...destinationMilestone,
            tasks: newDestinationTasks,
        };

        // Update the milestones array
        const newMilestones = Array.from(milestones);
        newMilestones[sourceMilestoneIndex] = updatedSourceMilestone;
        newMilestones[destinationMilestoneIndex] = updatedDestinationMilestone;

        // Dispatch the new milestones to the Redux store
        dispatch(taskActions.setMilestones(newMilestones));
        PutRequest(`${process.env.REACT_APP_URL}/task/${task._id}`, {
            milestoneId: destinationMilestone?._id,
            updatedBy: accountInfo?.data.fullName
        }).then(response => {
            TaskSocket.emitTaskUpdate(activeProject?._id, response.data, "", "milestone updated")
            // setTaskTrigger(prev => prev + 1);
        }).catch(error => {
            console.log("error updating milestone", error.data)
        })
    };


    return (
        <Layout containerClassName="max-w-none">
            {loader && (
                <Loader />
            )}
            {selectedTaskData &&
                <TaskView setTrigger={setTaskTrigger} projectId={activeProject?._id} />
            }
            <div className="flex justify-end">
                <div className="flex justify-end items-center max-lg:mt-[30px] mt-[50px]">
                    {isAdmin && (
                        <ReportGenerator />
                    )}
                    <div className="flex justify-end items-center px-[30px]">
                        <span className="text-[14px] mr-[10px]">Layout:</span>
                        <button
                            title="Kanban"
                            onClick={() => dispatch(taskActions.setLayout("kanban"))}
                            className={`border-y border-l px-[10px] py-[5px] ${layout === "kanban" && "bg-[#aaa]"}`}
                        >
                            <TbLayoutKanban size={18} />
                        </button>
                        <button
                            title="List"
                            onClick={() => dispatch(taskActions.setLayout("list"))}
                            className={`border px-[10px] py-[5px] ${layout === "list" && "bg-[#aaa]"}`}
                        >
                            <MdOutlineChecklist size={18} />
                        </button>
                    </div>
                    {isAdmin && projects.length > 0 && (
                        <div className="flex gap-[10px]">
                            <AddMilestone selectedProject={activeProject} setTrigger={setTaskTrigger} />
                        </div>
                    )}
                </div>
            </div>

            <div>
                {projects.length > 0 ? (
                    <div className="scroll-it">
                        {milestones.length > 0 ? (
                            layout === "kanban" ? (
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Kanban
                                        milestones={milestones}
                                        projectId={activeProject?._id}
                                        setTrigger={setTaskTrigger}
                                    />
                                </DragDropContext>
                            ) : (
                                <List
                                    milestones={milestones}
                                    projectId={activeProject?._id}
                                    deleteTaskHandler={deleteTaskHandler}
                                    setTrigger={setTaskTrigger}
                                />
                            )
                        ) : (
                            <div className='text-center mt-[50px] flex flex-col items-center justify-center'>
                                <p className='text-[20px] flex items-center'> <MdBrowserNotSupported className="mr-[10px]" /> No Task Added!</p>
                                <p>Add Milestone to add task!</p>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="flex flex-col justify-center items-center text-center h-[50vh]">
                        <h3>No Projects</h3>
                       { isAdmin &&
                        <p className="text-[14px] mt-[10px]">Create Project to add tasks!</p>
                       }
                    </div>
                )}
            </div>
        </Layout>
    );
};

export default Tasks;