import React, { useState } from 'react'
import Label from '../custom/label';
import TextInput from '../custom/textInput';
import { useDispatch } from 'react-redux';
import { uiActions } from '../../store/ui-slice';
import { decrypt } from '../../utils/decryption';
import Error from '../custom/error';
import Button from '../custom/button';

const VerifyOtp = ({setPage=()=>{}, setDisabled=()=>{}, received={} }) => {
    const dispatch = useDispatch();
    const [otp, setOtp] = useState("");
    const [error, setError] = useState("");

    function verificationHandler() {
        setError("")
        if (!otp) {
            setError('Enter OTP sent to your mail!!');
            setDisabled(false);
        } else if (otp !== decrypt(received.otp)) {
            setError("Incorrect OTP!");
            setDisabled(false);
        } else {
            dispatch(uiActions.setToastify({
                message: "OTP verified successfully!",
                isSuccess: true
            }));
            setPage(2);
        }
    };
    
    const handleKeyDown = (event) => {
        if (event.keyCode === 13 && !event.shiftKey) {
            event.preventDefault();
            verificationHandler();
        }
    };

    return (
        <div className='flex-col'>
            <h2 className='mb-[20px] text-center md:text-left'>Verify OTP</h2>

            <Error message={error} />
            <div className='gap-[20px] mt-[15px] mb-[15px]'>
                <Label
                    title="Enter OTP:"
                    htmlFor='otp'
                />
                <TextInput
                    id="otp"
                    value={otp}
                    placeholder='OTP'
                    className='max-w-full'
                    onChange={(e) => {
                        setOtp(e.target.value);
                        setError("");
                    }}
                    onKeyDown={handleKeyDown}
                />

            </div>
            <Button
                label="Verify"
                onClick={verificationHandler}
            />
        </div>
    )
}

export default VerifyOtp